<template>
  <div>
    <v-container grid-list-xs>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 md6 lg6 pa-2>
          <h1 class="display-2 b text-center">Let us know what you need</h1>
        </v-flex>
        <v-flex xs12 sm12 md8 lg8 pa-2>
          <section style="height: 200vh">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSeHNJIdI6ZF0OBcFH4yWQ_ujQUwwRtGJWCdI0pL23F57n-TTQ/viewform?embedded=true"
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              id="ifra"
            />
          </section>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      needs: [
        "CRM",
        "ERP",
        "LMS",
        "B2B E-Commerce",
        "B2C E-Commerce",
        "Digital Marketing Tools",
        "Boiler Plate App",
        "Platform Integrations",
        "Other Applications",
        "Consultancy",
        "Training",
      ],
      budgets: [
        "10,000 - 20,000 ETB (Boliler Plate app)",
        "20,000-30,000 ETB (Small Static Website)",
        "30,000-100,000 ETB (Big Static Website)",
        "30,000-100,000 ETB (Basic Dynamic App)",
        "50,000-200,000 ETB (Less Complex System)",
        "200,000-350,000 ETB (Medium Complex System)",
        "350,000 -600,000 ETB (Complex System)",
        ">600,000 ETB (Big System)",
      ],
    };
  },

  mounted() {
    const iframe = document.querySelector("#ifra");

    if (iframe.attachEvent) {
      iframe.attachEvent("onload", function () {
        this.loaded = true;
        console.log("iframe has been loaded");
      });
    } else {
      iframe.onload = function () {
        this.loaded = true;
        console.log("iframe has been loaded");
      };
    }
  },
};
</script>